export default {
  sp: {
    common: {
      success: '成功',
      send: '发送验证码',
      Password: '密码',
      mobile_phone: '手机号码',
      cancel: '取消',
      save: '确认',
      Required: '必填',
      are_you_sure_to_delete_it: '你确认删除吗？',
      yes: '确认',
      no: '取消',
      my_account: '个人中心',
      nameRules: '只能数字字母汉字任意组合最长30个字符',
      passwordRules: '8-16个字符，必须包含英文、数字、字符中的两种',
      select: '请选择',
      password_updated_successfully_tips: '密码修改成功',
      sign_in: '登录',
      select_one_item_least: '请至少选择一个商品',
      are_you_sure_to_delete_invalid_products: '您是否要清空所有的实效商品？'
    },
    commont: {
      success: '成功'
    },
    header: {
      store_location: '门店位置',
      order_track: '订单跟踪'
    },
    pdp: {
      check_availability: '检查可用性',
      trace_source: '跟踪'
    },
    footer: {
      follow_us: 'Follow Us',
      contact_us: 'Contact Us',
      brand_desc: `speedshop adalah Official Apple Premium & Authorized Reseller di
        Indonesia dan bagian dari PT Mitra Adiperkasa Tbk (MAP Group),
        peritel gaya hidup terdepan di Indonesia.`,
      company_name: 'speedshop',
      address:
        'Head office address: Floor 9, Lim Tower 3, 29A Nguyen Dinh Chieu, Da Kao Ward, District 1, HCMC.',
      registratrion_no: 'Registratrion No.: 0316998320',
      Phone: 'Email: hello@speedshop.com.vn',
      customer_service: '1900 3038 (9:00-22:00)',
      payment_options: 'Payment Options',
      payment_options_desc: `Registered and supervised by the Financial Services Authority and
        is an LPS guarantor participant.`,
      social_media: 'Social Media',
      copyright: 'Copyright 2020 SpeedShop. All rights reserved.',
      privacy_policy: 'Privacy Policy',
      term_conditions: 'Term & Conditions'
    },
    account: {
      LOGOUT: '登出'
    },
    account_setting: {
      account_setting: '账号信息',
      login_info: '登录信息',
      mobile: '手机号',
      email: '电子邮箱',
      validEmail: '验证邮箱',
      pleaseCheckTheEmailFormat: '请检查邮箱格式',
      validEmailTips: '验证邮件已发送，请按照提示进行验证',
      resend_n: '重新发送({x})秒',
      resend: '重新发送',
      changeEmail: '修改邮箱',
      YourEmailHasBeenSuccessfullyVerified: '您的邮箱已验证成功！',
      YourEmailHasBeenFailedVerified: '您的邮箱验证失败,请重试!',
      changePassword: '修改密码',
      originalPassword: '原始密码',
      newpassword: '新密码',
      confirmNewPassword: '确认新密码',
      TheTwoPasswordsEnteredAreInconsistent: '两次输入不一致',
      personalInformation: '个人信息',
      name: '姓名',
      birthday: '生日',
      gender: '性别',
      currentAddress: '所在地',
      changePersonInfo: '修改个人信息',
      providerName: '省份',
      cityName: '市区',
      subscriptionInfo: '订阅信息',
      productsYouAreInterested: '您感兴趣的商品:',
      modifySubscriptionInformation: '修改订阅信息',
      chooseYourSubscriptionTips: '选择您感兴趣的产品，我们将根据您的选择为您提供更贴心的服务。',
      pointsInformation: '积分信息',
      currentPoints: '目前积分',
      forPointsDetails: '积分明细请前往“Under Armour运动家俱乐部”小程序查询',
      pointsRules: '积分规则',
      emailHasBeenVerified: '邮箱已校验'
    },
    UA_Athletic_Club: {
      UA_Athletic_Club: 'UA运动家俱乐部',
      welcomeUA: '欢迎加入UNDER ARMOUR运动家俱乐部 <br /> 开启更多会员礼遇',
      sportsClubMemberShipBenefits: '运动家俱乐部会员权益',
      exclusiveWelcomeGift: '专属欢迎礼',
      exclusiveWelcomeGiftTips: '只为运动家量身打造',
      preferredPrivilege: '优选特权',
      preferredPrivilegeTips: '优先获取品牌资讯与会员尊享福利',
      rewards: '积分礼遇',
      rewardsTips: '累计消费购物积分',
      latestNews: '生日礼遇',
      latestNewsTips: '生日当月享专属生日礼遇',
      sportsmanExperience: '运动家体验',
      sportsmanExperienceTips: '品牌专属俱乐部体验与活动'
    },
    address: {
      my_address: '地址管理',
      first_name: '收件人姓名',
      your_first_name: '',
      your_mobile_phone: '',
      email: '邮箱',
      email_address: '',
      province: '省',
      select_province: '',
      city: '市',
      select_county_city: '',
      districts: '区',
      address: '具体地址',
      please_input_the_correct_phone_number: '请输入正确的手机号码'
    },
    orders: {
      my_orders: '我的订单'
    },
    order: {
      orderFilterPlaceHolder: '输入货号/商品名/订单编号',
      orderNo: '订单编号',
      createTime: '下单时间',
      orderEmpty: '您还没有此类订单',
      goPay: '继续支付',
      cancel_order: '取消订单',
      receive_order: '签收订单',
      ViewDetails: '查看订单详情',
      nav_myOrder: '订单',
      nav_order_detail: '订单详情',
      nav_order_return: '退货详情',
      retrun: '退货',
      Undo_retrun: '撤销退款',
      cancel: '取消',
      Undo_cancel: '撤销取消',
      Return_Refund_detail: '退货详情',
      fill_Logistics_info: '填写物流信息',
      Reject_Reason: '拒绝原因',
      Order_Info: '订单信息',
      Order_Amount: '订单金额',
      PayInfo: '支付信息',
      PayMethod: '支付方式',
      InvoiceInfo: '发票信息',
      InvoiceTitle: '发票抬头',
      InvoiceTaxCode: '纳税人识别号',
      InvoiceMobile: '电子发票通知手机号',
      InvoiceEmail: '电子发票通知邮箱',
      DeliveryInfo: '物流信息',
      DeliveryAddress: '物流地址',
      DeliveryMethod: '配送方式',
      DeliveryNo: '运单编号',
      LogisticsInformation: '配送信息',
      ProductInfo: '商品信息',
      Number: '数量',
      ProductAmount: '商品金额',
      DeliveryAmount: '运费',
      Total: '合计',
      Discount: '优惠金额',
      CopySuccess: '复制成功'
    },
    after_sales: {
      after_sales: '我的售后'
    },
    wishlist: {
      my_wishlist: '我的收藏',
      no_any_items: '暂无收藏'
    },
    coupon: {
      my_coupon: '我的优惠券',
      no_available_coupons: '暂无优惠券'
    },
    login: {
      Log_in: '登录/注册',
      Change_Password: '重置密码',
      New_Password: '确认密码',
      created_at: '创建时间'
    },
    shop_cart: { all_products: '全选' }
  }
};
