import en from '@/i18n/en.ts'
import vn from '@/i18n/vi.ts'
import zh from '@/i18n/zh.ts'
import id from '@/i18n/id.ts'

export default {
  en,
  vn,
  zh,
  id
}
