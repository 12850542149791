export default {
  sp: {
    //
  },
  header: {
    store_location: 'Lokasi toko',
    order_track: 'Pelacakan Pesanan'
  },
  footer: {
    follow_us: 'Ikuti kami',
    contact_us: 'Hubungi kami',
    brand_desc: `speedshop adalah Official Apple Premium & Authorized Reseller di
      Indonesia dan bagian dari PT Mitra Adiperkasa Tbk (MAP Group),
      peritel gaya hidup terdepan di Indonesia.`,
    company_name: 'speedshop',
    address:
      'Alamat kantor pusat: Floor 9, Lim Tower 3, 29A Nguyen Dinh Chieu, Da Kao Ward, District 1, HCMC.',
    registratrion_no: 'Pendaftaran No.: 0316998320',
    Phone: 'Email: hello@speedshop.com.vn',
    customer_service:
      'Setiap (Senin-Minggu Tidak Termasuk Hari Libur) 09:00 - 18:00',
    payment_options: 'Opsi Pembayaran',
    payment_options_desc: `Terdaftar dan diawasi oleh Otoritas Jasa Keuangan dan merupakan peserta penjamin LPS.`,
    social_media: 'Media Sosial',
    copyright:
      'Hak Cipta speedshop 2019-2025 Semua Hak Dilindungi Undang-undang.',
    privacy_policy: 'Kebijakan Privasi',
    term_conditions: 'Syarat & Ketentuan'
  }
}
