export default {
  sp: {
    // common: {
    //   SKU: 'SKU:',
    //   quantity: 'Jumlah:',
    //   delivery: 'Pengiriman:',
    //   cancel: 'Batal',
    //   change: 'Ganti',
    //   find_products_in_store: 'Cari Produk di speedshop',
    //   sign_in: 'Masuk',
    //   Register: 'Daftar'
    // },
    // login: {
    //   login: 'Masuk',
    //   log_in: 'Masuk',
    //   create_account: 'Daftar',
    //   forgot_password: 'Lupa Kata Sandi',
    //   register_now: 'Saya tidak mempunyai akun',
    //   Log_in: 'Masuk',
    //   Change_Password: 'Atur ulang kata sandi',
    //   no_account: 'Saya tidak mempunyai akun'
    // },
    // checkout: {
    //   release_date: 'Sekitar 3-7 hari kerja setelah tanggal rilis',
    //   about_n_working_days: 'Sekitar 3-7 hari kerja ',
    //   order_sum_n: 'Ringkasan Pesanan ({n}) item ',
    //   disclaimer: 'Sangkalan:',
    //   make_sure_mobile_email:
    //     'Pastikan Anda memasukkan nomor telepon dan alamat email yang aktif',
    //   pickup_code_instructions:
    //     'Kami akan mengirimkan kode OTP Anda melalui SMS ke nomor ponsel yang terdaftar yang akan digunakan untuk pengambilan produk',
    //   more_pick_up_info:
    //     'Untuk informasi lebih lanjut tentang pengambilan produk, silakan klik ',
    //   ready_for_pick_up_info:
    //     'Anda hanya dapat melakukan pengambilan produk apabila sudah menerima email dari kami',
    //   find_the_nearest_store: 'Temukan toko terdekat dari lokasi Anda',
    //   store_location: 'Lokasi Toko'
    // },
    // shop_cart: {
    //   purchase_limits: 'Batas Pembelian:',
    //   purchase_limits_n: 'Batas Pembelian: {n}',
    //   only_n_left_in_stock: 'Hanya tersisa {n} stok',
    //   find_a_store: 'Cari Toko ↓'
    // },
    // pdp: {
    //   available: 'Tersedia:',
    //   stock: 'Stok:',
    //   pickup: 'Pengambilan:',
    //   stock_status: 'Status Stok:',
    //   stock_not_available: 'Stok tidak tersedia',
    //   stock_available: 'Stok tersedia',
    //   purchase_limits_n: 'Batas Pembelian: {n}',
    //   check_availability: 'Periksa Ketersediaan',
    //   size_guide: 'Panduan Ukuran',
    //   maybe_like: 'Anda Mungkin Akan Suka',
    //   description: 'Deskripsi',
    //   trace_source: 'Ketertelusuran Seafood'
    // },
    // subscribe: {
    //   newsletter: 'Berlangganan',
    //   subscribe_tips:
    //     'Berlangganan untuk menjadi yang pertama mendengar tentang penawaran eksklusif dan kedatangan terbaru kami.',
    //   email_placeholder: 'Alamat email anda',
    //   subscribe_to_xx: 'Berlangganan',
    //   subscribe_desc:
    //     'Daftarkan email Anda untuk mendapatkan informasi terbaru tentang promosi dan penawaran menarik dari kami'
    // },
  },
  header: {
    store_location: 'Lokasi toko',
    order_track: 'Pelacakan Pesanan'
  },
  pdp: {
    check_availability: 'Check availability',
    trace_source: 'Seafood Traceability'
  },
  footer: {
    follow_us: 'Ikuti kami',
    contact_us: 'Hubungi kami',
    brand_desc: `speedshop adalah Official Apple Premium & Authorized Reseller di
      Indonesia dan bagian dari PT Mitra Adiperkasa Tbk (MAP Group),
      peritel gaya hidup terdepan di Indonesia.`,
    company_name: 'speedshop',
    address:
      'Alamat kantor pusat: Floor 9, Lim Tower 3, 29A Nguyen Dinh Chieu, Da Kao Ward, District 1, HCMC.',
    registratrion_no: 'Pendaftaran No.: 0316998320',
    Phone: 'Email: hello@speedshop.com.vn',
    customer_service:
      'Setiap (Senin-Minggu Tidak Termasuk Hari Libur) 09:00 - 18:00',
    payment_options: 'Opsi Pembayaran',
    payment_options_desc: `Terdaftar dan diawasi oleh Otoritas Jasa Keuangan dan merupakan peserta penjamin LPS.`,
    social_media: 'Media Sosial',
    copyright:
      'Hak Cipta speedshop 2019-2025 Semua Hak Dilindungi Undang-undang.',
    privacy_policy: 'Kebijakan Privasi',
    term_conditions: 'Syarat & Ketentuan'
  }
}
